@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: 'Roboto', sans-serif;
}
@keyframes move-balloons {
  0% {
    top: -20px;
  }
  100% {
    top: 100vh;
  }
}
.bulb {
  width: 100px;
  height: 100px;
  background-color: black;
  transition: background-color 1s;
}
.bulb.white {
  background-color: white;
}
.clock-number {
  font-family: Arial, sans-serif; /* You can change the font-family as per your preference */
  font-size: 2rem; /* Adjust the font size as needed */
  padding: 0 5px; /* Add spacing between digits */
  margin: 0 2px; /* Add some spacing between numbers */
}

.pulse {
  color: black; /* Default text color */
  animation: pulse-animation 0.5s ease-in-out infinite alternate;
}
@keyframes pulse-animation {
  0%, 100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Fully transparent */
  }
}
.blink {
  animation: blink 1s infinite;
  animation-fill-mode: both;
  }

  @keyframes blink {
    0% { opacity: 0 }
    50% { opacity: 1 }
    100% { opacity: 0 }
  }
  .blink-once {
    animation: blink-once 1s linear 1;
  }
  @media print {
  @page {
    size: A4;
  }

  .board-container {
    page-break-after: always;
    margin: 0;
    width: 100%;
    max-width: 21cm; /* A4 width */
    padding: 1cm;
    box-sizing: border-box;
  }

  .board-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
  }

  .board-table th,
  .board-table td {
    border: 1px solid black;
    padding: 8px;
  }

  .board-table th {
    background-color: lightgray;
  }

  .boards-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1cm; /* Adjust as needed */
  }

  .board-wrapper {
    width: calc(50% - 0.5cm); /* Adjust as needed */
  }
}
@media print {
  @page {
    size: A4;
    margin: 0;
  }

  .board-container {
    width: 100%;
    max-width: 21cm;
    padding: 1cm;
    box-sizing: border-box;
  }

  .board-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1cm;
    margin-bottom: 1cm;
  }

  .board-table th,
  .board-table td {
    border: 1px solid black;
    padding: 0;
    height: 20px;
    text-align: center;
  }

  .board-table tr:nth-child(odd) td:nth-child(odd),
  .board-table tr:nth-child(even) td:nth-child(even) {
    background-color: white;
  }

  .board-table tr:nth-child(odd) td:nth-child(even),
  .board-table tr:nth-child(even) td:nth-child(odd) {
    background-color: black;
  }

  .board-wrapper {
    width: 100%;
    margin-bottom: 1cm;
  }
}

  @keyframes blink-once {
    0% { opacity: 0; }
    50% { opacity: 1;  }
    100% { opacity: 0; }
  }
  @keyframes blink-once {
    0% { color: red; }
    50% { color: red;  }
    100% { color: red; }
  }
.buttons{
  background: transparent;
  color: white;
  border: 1px solid white;
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animate-balloon1 {
  width: 20px;
  height: 40px;
  background-color: #ff5733;
  border-radius: 50%;
  animation: move-balloons 5s linear infinite, fade-out 5s ease-in-out infinite;
  left: 20%;
}

.animate-balloon2 {
  width: 30px;
  height: 60px;
  background-color: #ffbd33;
  border-radius: 50%;
  animation: move-balloons 5s linear infinite 1s, fade-out 5s ease-in-out infinite 1s;
  left: 40%;
}

.animate-balloon3 {
  width: 25px;
  height: 50px;
  background-color: #33ff77;
  border-radius: 50%;
  animation: move-balloons 5s linear infinite 2s, fade-out 5s ease-in-out infinite 2s;
  left: 60%;
}

.animate-balloon4 {
  width: 35px;
  height: 70px;
  background-color: #33aaff;
  border-radius: 50%;
  animation: move-balloons 5s linear infinite 3s, fade-out 5s ease-in-out infinite 3s;
  left: 80%;
}

.animate-balloon5 {
  width: 15px;
  height: 30px;
  background-color: #ff33e6;
  border-radius: 50%;
  animation: move-balloons 5s linear infinite 4s, fade-out 5s ease-in-out infinite 4s;
  left: 90%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    
}
.bg{
  background:rgb(34,34,34);
}
input[type="color"]::-webkit-color-swatch-wrapper {
  overflow: hidden;
}

input[type="color"]::-webkit-color-swatch {
  background: transparent;
  border: 0;
}

* {
  box-sizing: border-box;
}

a {
  color: white !important;
  text-decoration: none !important;
}
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.texts{
  /* color: #861010; */
  color: #4d4b4b;
}
.main-container {
  padding: 2.5rem/* 40px */;
  display: flex;
  outline: none;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  position: fixed;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}
.tables {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  min-width: fit-content;
  font-size: 1rem;
  border-bottom-width: 1px;
  border-top-width: 1px;
}
.tables caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
tbody {
  border-top-width: 1px;
  border-color: #838181;
}
.tables tr {
  background-color: #f5f5f4;

}
.tables tr:nth-child(even) {
  background-color:  #e7e5e4;
}
.dark .tables tr {
  background-color: #1e293b;
}
.dark .tables tr:nth-child(even) {
  background-color:#1f2937;
}

.tables td {
  color: rgb(41 37 36);
  padding: 0.25em;
  text-align: left;
}
.dark .tables td {
  color: #f1f5f9;
}

.tables th {
  background:#808083;
  color: #ffffff;
  padding: 0.25em;
  text-align: left;
  font-size: 0.99em;
  cursor: pointer;
  overflow: hidden;
}
@media (max-width: 1000px) {
  .tables th {
    font-size: 0.8em; 
  }
}

.dark .tables th {
 background: #1e273b;
 color: #cbd5e1;
}

.table-title {   
  display: flex;
  background: #273c63;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1em;
  font-weight: 700;
  border-top-left-radius: 1rem/* 16px */;
  border-top-right-radius: 1rem;
  padding: 1rem; 
}
.dark .table-title {
  background-color: #1e273b;
  color: #cbd5e1;
}
.alert-no-data {
  color: #664d03;
  background-color: #fff3cd;
  font-size: 1rem;
  font-weight: 700;
  padding: 1rem;
  width: 100%;
  border: 1px solid #ffecb5;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.search-value {
  text-decoration: underline;
  font-style: bold;
  padding: 0 0.264rem;
}
.attachment-container {
  /* width: 140px; */
  padding: 1rem;
  border-radius: 1rem;
}
.drop-file-icon {
  padding: 0.5rem;
  border: 1px solid  #ce9f2c ;
  border-radius: 1rem;
  background-color: #1a572d ;
}

.drop-file-name-container {
  display: flex;
  width: 140px;
  font-weight: 600;
	font-size: 1rem; 
	line-height: 1.5rem; 
	padding: 0 1rem .5rem .5rem;
}

.drop-file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.drop-file-dd-icon {
  display: flex;
  justify-content: end;
}

.file-upload-dropdown-container {
  display: none;
  position: relative;
  /* background-color: #f3f4f6; */
  min-width: max-content;
  border-radius: 1rem;
  box-shadow: 0px 8px 16px 0px hwb(0 0% 100% / 0.2);
	padding: .5rem .25rem;	
	z-index: 1000;
}

.file-upload-dropdown-container{
  display: block;
	left: 100%;
  top: 100%;
 }

.file-upload-dropdown-item {
	font-weight: 600;
	font-size: 1rem; 
	line-height: 1.5rem; 
	padding: 0 2rem .25rem .5rem;
	color: #030712; 
}
.file-previewer {
  max-height: 400px;
  overflow-y: auto;
  max-width: 100%;
  overflow-x: auto;
  margin: 0;
  padding-left: 0;
}
.slider {
  -webkit-appearance: none;
  width: 50%;
  height: 6px;
  background: #FF9F66;
  outline: none;
  border: 3px solid #dedde7;
}

/* for chrome/safari */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 30px;
  background: #002379;
  cursor: pointer;
  border: 5px solid #002379;
  border-radius: 4px;
}
video::-webkit-media-controls-timeline {
  display: none;
}
.vids {
  pointer-events: none;
}
.height {
  height: 100vh !important;
}
/* for firefox */
.slider::-moz-range-thumb {
  width: 10px;
  height: 20px;
  background: #000;
  cursor: pointer;
  border: 5px solid lightgreen;
  border-radius: 4px;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  justify-content: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #273c63 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.vnicornMeme {
  position: relative;
  width: 100%;
  margin: auto;
}

.vnicornMeme > h2 {
  position: absolute;
  width: 80%;
  height: 200px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  margin: 15px 0;
  padding: 0 5px;
  font-family: impact, sans-serif;
  font-size: 2em;
  text-transform: uppercase;
  color: black;
  letter-spacing: 1px;
  text-shadow: 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000,
    -2px 2px 0 #000, 0 2px 0 #000, 2px 0 0 #000, 0 -2px 0 #000, -2px 0 0 #000,
    2px 2px 5px #000;
}
.author {
  font-family: impact, sans-serif;
  font-size: 1em;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  text-shadow: 2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000,
    -2px 2px 0 #000, 0 2px 0 #000, 2px 0 0 #000, 0 -2px 0 #000, -2px 0 0 #000,
    2px 2px 5px #000;
}

@media (max-width: 767px) {
  .vnicornMeme > h2 {
    font-size: 1em;
  }
}

.vnicornMeme > i {
  position: absolute;
  left: 0%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.7em;
  color: white;
  background: #00000078;
  padding: 5px;
  margin: 5px;
  border-radius: 2px;
}

.vnicornMeme > .bottomText {
  bottom: 0;
}

.vnicornMeme > .topText {
  top: 0;
}

.vnicornMeme > .authorText {
  bottom: 0;
  float: left;
}

.wrapper {
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, #04fafd, 5%, #119dff, 50%, #030423);
  position: absolute;
}
.wrapper h1 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-family: sans-serif;
  letter-spacing: 1px;
  word-spacing: 2px;
  color: #fff;
  font-size: 40px;
  font-weight: 888;
  text-transform: uppercase;
}
.wrapper div {
  height: 20px;
  width: 20px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  position: absolute;
  top: 10%;
  left: 10%;
  animation: 4s linear infinite;
}
div .dot {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background: rgb(163, 154, 19);
  position: absolute;
  top: 20%;
  right: 20%;
}
.wrapper div:nth-child(1) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(2) {
  top: 60%;
  left: 80%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(3) {
  top: 40%;
  left: 40%;
  animation: animate 3s linear infinite;
}
.wrapper div:nth-child(4) {
  top: 66%;
  left: 30%;
  animation: animate 7s linear infinite;
}
.wrapper div:nth-child(5) {
  top: 90%;
  left: 10%;
  animation: animate 9s linear infinite;
}
.wrapper div:nth-child(6) {
  top: 30%;
  left: 60%;
  animation: animate 5s linear infinite;
}
.wrapper div:nth-child(7) {
  top: 70%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(8) {
  top: 75%;
  left: 60%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(9) {
  top: 50%;
  left: 50%;
  animation: animate 6s linear infinite;
}
.wrapper div:nth-child(10) {
  top: 45%;
  left: 20%;
  animation: animate 10s linear infinite;
}
.wrapper div:nth-child(11) {
  top: 10%;
  left: 90%;
  animation: animate 9s linear infinite;
}
.wrapper div:nth-child(12) {
  top: 20%;
  left: 70%;
  animation: animate 7s linear infinite;
}
.wrapper div:nth-child(13) {
  top: 20%;
  left: 20%;
  animation: animate 8s linear infinite;
}
.wrapper div:nth-child(14) {
  top: 60%;
  left: 5%;
  animation: animate 6s linear infinite;
}
.wrapper div:nth-child(15) {
  top: 90%;
  left: 80%;
  animation: animate 9s linear infinite;
}
@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(70deg);
  }
  100% {
    transform: scale(1.3) translateY(-100px) rotate(360deg);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(7, 228, 180);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* === Root */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "montserrat regular", sans-serif;
}

/* === body */
.number-container {
  text-align: center;
}

.number {
  font-size: 36px;
  transition: color 1s ease; /* Smooth transition for color change */
}
@keyframes animateCalled {
  0% { color: green; }
  33.33% { color: red; }
  66.66% { color: blue; }
  100% { color: green; }
}

.animate-called {
  animation: animateCalled 1s linear infinite; /* 3 seconds for one full cycle */
}



.red {
  color: red;
}
h2 {
  width: 35%;
  text-align: center;
  border-bottom: 1px solid rgb(219, 219, 219);
  line-height: 0.1em;
  margin: 10px 0 20px;
  text-transform: uppercase;
  font-size: 15px;
}

h2 span {
  background: #fff;
  padding: 0 10px;
}

h4::selection {
  background-color: #f87171;
  color: white;
}

/* Media queries */

@media screen and (max-width: 991px) {
  h2 {
    width: 50%;
  }
}

@media screen and (max-width: 425px) {
  h2 {
    width: 80%;
  }
}
@keyframes animate-bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.animate-bounce {
  animation: animate-bounce 1s ease-in-out infinite;
}
.scrollable-y {
  height: 100%;
  overflow-y: auto;
}
.parent-container {
  overflow-x: hidden;
}
.tables {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  min-width: fit-content;
  font-size: 0.875rem;
}
.tables caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
.tables tr:nth-child(even) {
  background-color: #f2f2f2;
}
.tables tr {
  /* background-color: #e5e7eb; */
  padding: 0.35em;
}

.tables th,
.tables td {
  padding: 0.725em;
  padding-left: 20px;
  text-align: left;
  border-bottom: 0px solid gray;
}

.tables th {
  font-size: 0.99em;
  letter-spacing: 0.1em;
  background-color: #273c63;
  color: #fff;
  cursor: pointer;
}

.sticky-header {
  position: sticky;
  top: -0.5px;
}

/* Responsive Table design for Aggregation Insights on Control Analysis */
.rtables {
  border: 1px solid #ddd;
  width: 70%;
}

.rtables caption {
  font-size: 1.3em;
}

.rtables thead {
  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
/*
  .rtables tr {
    border-bottom: 2px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }
*/
.rtables td {
  border-bottom: 1px solid #ddd;
  display: block;
  font-size: 0.8em;
  text-align: right;
  padding: 5px;
}

.rtables td::before {
  content: attr(data-label);
  float: left;
  font-weight: bold;
}

.rtables td:last-child {
  border-bottom: 0;
}
/* ------- */
.modalBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  margin-left: 1000px;
  margin-bottom: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding-bottom: 400px;
}
.modalContainer {
  z-index: 100;
  width: 900px;
  /* height: 600px; */
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}
.color_id {
  color: #286091;
}
.paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #ce9f2c;
  color: #ce9f2c;
  background-color: #ce9f2c;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white !important;
  background-color: #ce9f2c;
}

.paginationActive a {
  color: white !important;
  background-color: #273c63;
  opacity: 0.9;
  cursor: default;
}

.paginationActive a:hover {
  color: white !important;
  background-color: #273c63;
  opacity: 0.9;
}

.paginationDisabled a {
  color: green;
  background-color: grey;
  opacity: 0.4;
}

.paginationDisabled a:hover {
  color: white;
  background-color: grey;
  opacity: 0.4;
  cursor: default;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.image-container {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.button-container {
  position: relative;
}

.shuffle {
  animation: shuffleAnimation 0.3s infinite;
}

@keyframes shuffleAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
